const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export function formatMonth(date: Date) {
  return months[date.getMonth()];
}

export function formatDate(date: Date) {
  return `${formatMonth(date)} ${date.getDate()}, ${date.getFullYear()}`;
}

export function isNowBetween(start: Date, end: Date) {
  const now = new Date();
  return now >= start && now <= end;
}

function isDev() {
  return ['localhost:3000', 'localwww.nslookup.io'].includes(window.location.host);
}

function isDevOrBetween(start: Date, end: Date) {
  return isDev() || isNowBetween(start, end);
}

export function showRedirectPizzaAds() {
  return true;
}

export function showClouDNSAds() {
  return isDevOrBetween(new Date('2024-12-09'), new Date('2024-12-23'));
}

export function showEntriAds() {
  return isDevOrBetween(new Date('2024-05-30'), new Date('2024-08-01'));
}

export function showUsersearchAd() {
  return isDevOrBetween(new Date('2024-05-30'), new Date('2024-07-01'));
}
